import { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';

import { Button, message } from 'antd';

const ScanQRCode = (parameters: any) => {
  const { uuidSetter } = parameters;
  const [scanResult, setScanResult] = useState<string | null>(null);
  const [showScanner, setShowScanner] = useState(false);
  const [shouldScan, setShouldScan] = useState(false);



  // Fonction appelée après la détection du QR code
  const handleScan = (data: any) => {
    if (data) {
      setScanResult(data[0].rawValue); // Stocker le code QR scanné
      uuidSetter(data[0].rawValue);
      setShowScanner(false); // Fermer le scanner
    }
  };

  // Fonction appelée en cas d'erreur de scan
  const handleError = (err: any) => {
    console.error(err);
    //message.error('Erreur lors du scan du QR code');
  };

  // Fonction pour envoyer la requête avec le code scanné

  return (
    <>
      {/* Bouton pour démarrer le scanner */}
      <Button
        className='bg-primary flex flex-col m-auto'
        type="primary" onClick={() => showScanner ? setShowScanner(false) : setShowScanner(true)}>
        {/* <span className='text-3xl'>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"><path fill="#1F7A8C" d="M22 24H10a2 2 0 0 1-2-2v-3h2v3h12v-3h2v3a2 2 0 0 1-2 2M2 15h28v2H2zm22-2h-2v-3H10v3H8v-3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm6-3h-2V4h-6V2h8zM4 10H2V2h8v2H4zm6 20H2v-8h2v6h6zm20 0h-8v-2h6v-6h2z" /></svg>
        </span> */}
        <span>
          {showScanner ? 'Fermer le Scanner' : " Scanner un QR Code"}
        </span>
      </Button>

      {/* Affichage du scanner si le bouton est cliqué */}
      {showScanner && (
        <Scanner onScan={handleScan} constraints={{
          facingMode: "user",
          width: 400,
          height: 400,
          frameRate: 30
        }} />
      )}

      {/* Afficher le code scanné */}
      {scanResult && (
        <div>
          <p>QR Code scanné : {scanResult}</p>
        </div>
      )}
    </>
  );
};

export default ScanQRCode;
