import { createDataFunction, deleteDataFunction, updateDataFunction } from "../services_hooks/apiFunction";

export class Distribution {
  farm_input: number;
  quantity: number;
  //distribution_session: number;
  //static url: string = "distributions";

  constructor(farm_input: number, quantity: number) {
    this.farm_input = farm_input;
    this.quantity = quantity;
    //this.distribution_session = distribution_session;
  }

  static fromJson(json: any): Distribution {
    return new this(json.farm_input, json.quantity);
  }
}

export class DistributionRequest {
  distributions: Distribution[];
  farmer: number;
  recipient_id_image: string;
  recipient_image: string;
  static url: string = "sessions";


  constructor(distributions: Distribution[], farmer: number, recipient_id_image: string, recipient_image: string) {
    this.distributions = distributions;
    this.farmer = farmer;
    this.recipient_id_image = recipient_id_image;
    this.recipient_image = recipient_image;
  }

  static fromJson(json: any): DistributionRequest {
    const distributions = json.distributions.map((dist: any) => Distribution.fromJson(dist));
    return new this(distributions, json.farmer, json.recipient_id_image, json.recipient_image);
  }
  static setSession(body: any): any {
    return createDataFunction(`${this.url}/`, body);

  }
  static updateSession(id: any, body: any): any {
    return updateDataFunction(`${this.url}/${id}/`, body);
  }
  static deleteSession(id: any): any {
    return deleteDataFunction(`${this.url}/${id}/`, {});
  }

}
